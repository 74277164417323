
  import { Component, Mixins } from "vue-property-decorator";
  import { bus } from "@/main";
  import HasNotaryModal from "./has-notary-modal";
  import { Mandate } from "@/store/modules/mandate.store";

  @Component
  export default class NotaryDeleteModal extends Mixins(HasNotaryModal) {
    mounted() {
      bus.$off("show-delete-notary");
      bus.$on("show-delete-notary", async (type: "BUYER", subject: Activity) => {
        console.log("delete");
        this.notaryType = type;

        this.subject = subject;

        this.show = true;
      });
    }

    async submit(form: FormClass) {
      try {
        if (this.notaryType === "SELLER") {
          // Dead end flow for now!
          return;
        }

        if (this.notaryType === "BUYER") {
          await this.handleBuyerNotarySubmit();
        }

        this.$toast.open({ message: this.$t("views.contacts.notary.delete_success") as string, type: "success", position: "bottom-right" });

        this.handleClose();
      } catch (e) {
        this.errorResponse = this.formatErrors(e);
      } finally {
        form.reset();
      }
    }

    /**
     * @name Handle buyer notary submit
     * @description Store the buyer notary in the notary field of the activity
     *
     */
    async handleBuyerNotarySubmit() {
      if (!this.subject || !this.subject.hasOwnProperty("buyer_notary")) {
        return;
      }

      const a = this.subject as Activity;

      await this.patchActivity({ id: a.id, buyer_notary: null });
    }
  }
