import Vue from "vue";
import Component from "vue-class-component";

@Component
export class HasNotaryOptions extends Vue {
    notaryOptions: Array<{ offices: number[]; label: string; value: string }> = [];

    async getNotaryOptions() {
        const options = await import("@/assets/json/notary.json");

        // @ts-ignore
        this.notaryOptions = options.default;
    }

    get officeNotaryOptions() {
        // @ts-ignore
        return this.selectedOffice ? this.notaryOptions.filter((option) => option.offices.includes(this.selectedOffice.id)) : this.notaryOptions;
    }
}
