import { Action } from "vuex-class";
import { Component, Ref, Mixins } from "vue-property-decorator";
import { FormBase, FormSubmit, FormSelect } from "@/components/forms";
import { bus } from "@/main";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { HasNotaryOptions } from "@/mixins/has-notary-options";
import { UpdateMandatePayload, Mandate } from "@/store/modules/mandate.store";

@Component({
  components: {
    LayoutModal: () => import("@/layouts/components/modal.vue"),
    FormBase,
    FormSelect,
    FormSubmit,
  },
})
export default class HasNotaryModal extends Mixins(HasFormErrors, HasNotaryOptions) {
  @Action("mandate/patch") patchMandate!: (payload: UpdateMandatePayload) => Promise<Mandate>;
  @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Promise<Activity>;

  @Ref() form!: FormClass;

  show = false;

  notaryType: "SELLER" | "BUYER" | null = null;

  subject: Mandate | Bid | Activity | null = null;

  selectedNotary = "";

  async submit(form: FormClass) {
    try {
      if (this.notaryType === "SELLER") {
        await this.handleSellerNotarySubmit();
      }

      if (this.notaryType === "BUYER") {
        await this.handleBuyerNotarySubmit();
      }

      this.$toast.open({ message: this.$t("views.contacts.notary.create_success") as string, type: "success", position: "bottom-right" });

      this.handleClose();
    } catch (e) {
      this.errorResponse = this.formatErrors(e);
    } finally {
      form.reset();
    }
  }

  /**
   * @name Handle seller notary submit
   * @description Store the seller notary in the active BO under the notary field.
   *
   */
  async handleSellerNotarySubmit() {
    
    if (!this.subject || !this.subject.hasOwnProperty("details")) {
      return;
    }
    const m = this.subject as Mandate;

    await this.patchMandate({ id: m.id, activity_id: m.activity_id, details: { ...m.details, article_2_notary: this.selectedNotary } });
  }

  /**
   * @name Handle buyer notary submit
   * @description Store the buyer notary in the notary field of the activity
   *
   */
  async handleBuyerNotarySubmit() {
    if (!this.subject || !this.subject.hasOwnProperty("buyer_notary")) {
      return;
    }

    const a = this.subject as Activity;

    await this.patchActivity({ id: a.id, buyer_notary: this.selectedNotary });
  }

  handleClose() {
    this.clearErrors();

    this.notaryType = null;

    this.subject = null;

    this.show = false;
  }
}
